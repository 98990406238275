export const AllowedColumnsInFile = Object.freeze({
  DATE: "DateOp",
  TYPE: "TypeOp",
  REF_OP: "RefOp",
  CMR: "CMR",
  REF_SHIP: "ShipperRef",
  SHIP: "ShipperName",
  REF_TPS: "CarrierRef",
  TPS: "CarrierName",
  REF_ST: "SubcontractorRef",
  ST: "SubcontractorName",
  REF_REC: "ReceiverRef",
  REC: "ReceiverName",
});

export const FIRST_PALLET_COLUMN = 12;
