export class ProcessResult {
  constructor() {
    this.resultProcessList = [];
    this._numberOfErrors = 0;
    this._numberOfWarnings = 0;
  }

  get numberOfErrors() {
    this._numberOfErrors = this.resultProcessList.filter((item) => item.status === "error" || item.status === "blockingerror").length;
    return this._numberOfErrors;
  }

  get numberOfWarnings() {
    this._numberOfWarnings = this.resultProcessList.filter((item) => item.status === "warning").length;
    return this._numberOfWarnings;
  }

  addResult(result) {
    this.resultProcessList.push(result);
  }

  removeResultsForRow(rowIndex) {
    this.resultProcessList = this.resultProcessList.filter((item) => String(item.rowIndex) !== String(rowIndex));
  }

  clear() {
    this.resultProcessList = [];
    this._numberOfErrors = 0;
    this._numberOfWarnings = 0;
  }
}
